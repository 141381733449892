<mat-stepper [linear]="isLinear" #stepper class="angular-stepper">
    <mat-step [stepControl]="firstFormGroup">
        <form [formGroup]="firstFormGroup">
            <ng-template matStepLabel><mat-icon class="mr-2 stepper-icon"
                    fontSet="material-icons-outlined">contacts</mat-icon>
                <span class="heading"> Account Details</span> <mat-icon class="ml-2 arrow-icon">chevron_right</mat-icon>
            </ng-template>
        </form>
    </mat-step>
    <mat-step>
        <form [formGroup]="secondFormGroup">
            <ng-template matStepLabel>
                <mat-icon class="material-symbols-outlined mr-2 stepper-icon">
                    article_shortcut
                </mat-icon>
                <span class="heading">Re-Occurring Expense Details</span>
                <mat-icon class="ml-2 arrow-icon">chevron_right</mat-icon>
            </ng-template>
        </form>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel> <mat-icon class="mr-2 stepper-icon"
                fontSet="material-icons-outlined">business_center</mat-icon>
            <span class="heading">Cost Centre</span>
            <mat-icon class="ml-2 arrow-icon">chevron_right</mat-icon></ng-template>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel><mat-icon class="mr-2 stepper-icon"
                fontSet="material-icons-outlined">group</mat-icon><span class="heading">Core
                Team</span>
            <mat-icon class="ml-2 arrow-icon">chevron_right</mat-icon></ng-template>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>
            <mat-icon class="material-symbols-outlined mr-2 stepper-icon">
                source_environment
            </mat-icon>
            <span class="heading">Project</span> <mat-icon class="ml-2 arrow-icon">chevron_right</mat-icon>
        </ng-template>
    </mat-step>
    <mat-step>
        <ng-template matStepLabel>
            <mat-icon class="material-symbols-outlined mr-2 stepper-icon">
                crowdsource
            </mat-icon>
            <span class="heading"> Resource</span>
        </ng-template>
    </mat-step>
</mat-stepper>